import { useState, useEffect } from "react";
import dayjs from "dayjs";

const CountdownTimer = () => {
  const [countdown, setCountdown] = useState(calculateCountdown());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function calculateCountdown() {
    const now = dayjs();
    const currentYear = now.year();
    // Determine if it's already January 1st of the next year
    const nextYear =
      now.month() === 0 && now.date() === 1 ? currentYear : currentYear + 1;
    const targetTime = dayjs(`${nextYear}-01-01T00:00:00`);
    const timeRemaining = targetTime.diff(now, "second");

    const days = Math.floor(timeRemaining / (24 * 60 * 60));
    const hours = Math.floor((timeRemaining % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((timeRemaining % (60 * 60)) / 60);
    const seconds = Math.floor(timeRemaining % 60);

    return { days, hours, minutes, seconds };
  }

  const time = dayjs()
    .set("days", countdown.days)
    .set("hour", countdown.hours)
    .set("minute", countdown.minutes)
    .set("second", countdown.seconds);

  let formattedTime;

  if (countdown.days > 0) {
    formattedTime = time.format("d:HH:mm:ss");
  } else {
    formattedTime = time.format("HH:mm:ss");
  }

  return <>{formattedTime}</>;
};

export default CountdownTimer;
