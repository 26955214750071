import PocketBase from "pocketbase";
import { QueryClient } from "@tanstack/react-query";
import { TypedPocketBase } from "../types/global";

const POCKETBASE_URL = process.env.REACT_APP_API_URL;

if (!POCKETBASE_URL) {
  throw new Error("REACT_APP_API_URL not configured");
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const pocketbase = new PocketBase(POCKETBASE_URL) as TypedPocketBase;

export { POCKETBASE_URL, queryClient, pocketbase };
