import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./lib/config";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./components/Root";
import Quiz from "./components/Quiz";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "q/:questionId",
        element: <Quiz />,
      },
    ],
  },
]);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
