import { FormEvent, useEffect, useState } from "react";
import { pocketbase } from "../lib/config";
import { Question } from "../types/global";
import { getRandomItem } from "../util/getRandomItem";
import CountdownTimer from "./CountdownTimer";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const fetchQuestion = async (id: string, input?: string | null) => {
  return await pocketbase.collection("questions").getOne(id, {
    ...(input && {
      query: {
        input: input,
      },
    }),
    requestKey: null,
  });
};

const errors = [
  "Tyvärr, det där var fel.",
  "Försök igen",
  "Lol, nej.",
  "Nära, men så väldigt fel.",
];

function Quiz() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [nextQuestionId, setNextQuestionId] = useState<string | null>();
  const [question, setQuestion] = useState<Question | null>(null);
  const [input, setInput] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchError, setIsFetchError] = useState(false);
  const [inputError, setInputError] = useState<string | null>(null);

  const fetchNextQuestion = async (
    questionId: string,
    input?: string | null
  ) => {
    setIsLoading(true);
    setIsFetchError(false);
    setInputError(null);
    try {
      const { ...data } = await fetchQuestion(questionId, input);
      setQuestion(data);
      setNextQuestionId(data.next_question);
      navigate(`/q/${data.id}` + (input ? `?input=${input}` : ""));
      setInput("");
    } catch (e: any) {
      console.error(e);
      setIsFetchError(true);
      if (e.status === 404 && input !== "") {
        setInputError(getRandomItem(errors));
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (question === null && params.questionId && !isFetchError) {
      fetchNextQuestion(params.questionId, searchParams.get("input"));
    }
  });

  const hasNextQuestion = () => {
    if (nextQuestionId !== "") {
      return true;
    }
    return false;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    if (nextQuestionId && hasNextQuestion()) {
      fetchNextQuestion(nextQuestionId, input.toLowerCase().trim());
    }
    e.preventDefault();
  };

  return (
    <div className="max-w-prose mx-auto pt-4 px-4 pb-20">
      <div className="flex justify-end mb-5">
        <div className="bg-red-500 text-white py-1 px-3 rounded-full">
          <CountdownTimer />
        </div>
      </div>

      {question ? (
        <>
          {question?.text && (
            <div
              className="prose mb-5"
              dangerouslySetInnerHTML={{ __html: question.text }}
            ></div>
          )}
          {hasNextQuestion() && (
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                required
                placeholder="Skriv ditt svar"
                className="w-full rounded-lg mb-5"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />

              {inputError && (
                <div className="bg-red-200 text-red-800 p-3 rounded-lg mb-5">
                  {inputError}
                </div>
              )}

              <input
                type="submit"
                className="button w-full h-10 bg-blue-500 rounded-lg cursor-pointer select-none active:translate-y-2 active:[box-shadow:0_0px_0_0_#1b6ff8,0_0px_0_0_#1b70f841] active:border-b-[0px] disabled:translate-y-0 disabled:transition-none disabled:bg-gray-500 disabled:[box-shadow:0_10px_0_0_#4b5563,0_15px_0_0_#e5e7eb] disabled:border-b-[1px] transition-all duration-150 [box-shadow:0_10px_0_0_#1b6ff8,0_15px_0_0_#1b70f841] border-b-[1px] border-blue-400 disabled:border-gray-400 text-white text-lg font-medium"
                value={!isLoading ? "Skicka" : "Laddar..."}
                disabled={isLoading}
              />
            </form>
          )}
        </>
      ) : (
        "Laddar..."
      )}
    </div>
  );
}

export default Quiz;
